import { Avatar, Dialog, DialogTitle, List } from "@mui/material";
import { ReactElement } from "react";
import { ProjectDetails } from "../../logic/customTypes";
import { generateTheme } from "../../logic/logic";

export interface GenericListDialogProps {
    open: boolean;
    onClose: (value: string) => void;
    dialogList: ReactElement[];
    title: string;
    projectInfo?: ProjectDetails;
}

export default function GenericListDialog(props: GenericListDialogProps) {
    if(props.projectInfo){
        const currentTheme = generateTheme(props.projectInfo.themeColor);
        return (
            <Dialog onClose={props.onClose} open={props.open} 
                PaperProps={{
                style: {
                    backgroundColor:currentTheme.bgColor, 
                    color:currentTheme.textColor,
                    borderRadius: '15px'
                },
                }}
            >
                <DialogTitle>
                    <span style={{display: 'flex', flexDirection: 'row'}}>
                        <Avatar src={props.projectInfo.logo} style={{marginRight: '15px'}}/>
                        {props.title}
                    </span>
                </DialogTitle>
                <List>
                    {props.dialogList}
                </List>
            </Dialog>
        );
    }else{
        return (
            <Dialog onClose={props.onClose} open={props.open}>
                <DialogTitle>
                    {props.title}
                </DialogTitle>
                <List>
                    {props.dialogList}
                </List>
            </Dialog>
        );
    }
}