import './App.css';
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom"
import { Home } from './components/Home';
import { Gallery } from './components/Gallery';
import { NotFound } from './components/NotFound';
import { ModelViewerPro } from './components/Viewer/ViewerPro';
import { SEO } from './components/Seo';


function App() {
  return (
      <div className="App">
        <SEO
            title={'Solanacorn AR'}
            description={'Explore your reality'}
            name='Solanacorn'
            type='article' 
        />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/gallery" element={<Gallery/>} />
            <Route path="/unknown" element={<NotFound/>} />
            {/* <Route path="/:modelName" element={<ModelViewer />} /> */}
            <Route path="/:modelName" element={<ModelViewerPro />} />
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
