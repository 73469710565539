import { Avatar, Card, CardContent, CardHeader,IconButton, } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import { SEO } from "./Seo";
import { generateTheme, getLabsInfo, getProjectList, handleConfirmExternal } from "../logic/logic";
import { ProjectDetails } from "../logic/customTypes";
import { PreviewTile } from "./PreviewTile";
import React from "react";

function Home() {
    const projectDetails : ProjectDetails = getLabsInfo();
    // theme related
    const currentTheme = generateTheme(projectDetails.themeColor);
    const projectTiles = getProjectList().map(p => {
        return (<PreviewTile projectDetails={p}/>)
    });
   
    return (
        <>
            <SEO
                title={'Solanacorn AR'}
                description={'Explore your reality'}
                name='Solanacorn'
                type='article' 
            />
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <Card style={{ 
                        width: '360px', 
                        height: '640px', 
                        background: currentTheme.bgColor,
                        color: currentTheme.textColor, 
                    }}>
                        <CardHeader
                            avatar={<Avatar src={projectDetails.logo} />}
                            action={
                            <IconButton 
                                onClick={()=>{handleConfirmExternal("https://twitter.com/intent/tweet?text=Check out this AR powered by @CornOnSolana: " + window.location.href,'x')}}
                                style={{background:currentTheme.accentColor, color:currentTheme.textColor}}
                            >
                                <ShareIcon  />
                            </IconButton>}
                            sx={{background:currentTheme.accentColor, color:currentTheme.textColor}}
                            title="Solanacorn AR"
                            subheader={<span style={{background:currentTheme.accentColor, color:currentTheme.textColor}}>{projectDetails.projectName.toUpperCase()}</span>} />
                        <CardContent style={{display:"grid",gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px'}}>
                             {projectTiles}
                        </CardContent>
                    </Card>
                </div>
        </>
    );
}

export {Home};