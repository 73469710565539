import { Dialog, DialogTitle, List, ListItem, ListItemButton } from "@mui/material";
import { getSocialPlatform, handleConfirmExternal } from "../../logic/logic";
import { ImEye } from "react-icons/im"
import { ProjectDetails, SocialFilterDetails } from "../../logic/customTypes";
import GenericListDialog from "./GenericListDialog";

export interface FiltersListDialogProps {
    open: boolean;
    onClose: (value: string) => void;
    socialFilters: SocialFilterDetails[];
    projectInfo: ProjectDetails;
  }
  
  export default function FiltersListDialog(props: FiltersListDialogProps) {
    const { onClose, socialFilters, projectInfo, open } = props;

    let filterPlatform = 'Unknown';

    if(socialFilters.length > 0){
        filterPlatform = getSocialPlatform(socialFilters[0].platform);
    }else{
        onClose("");
    }
  
    const handleClose = () => {
      onClose("");
    };
  
    const handleListItemClick = (url: string, platform: string) => {
        onClose("");
        handleConfirmExternal(url, platform);
    };

    const dialogList = socialFilters.map((f) => (
        <ListItem disableGutters key={f.url}>
            <ListItemButton onClick={() => handleListItemClick(f.url, f.platform)} style={{display:'flex', flexDirection:'row', borderTop:'2px solid grey'}}>
                <ImEye style={{margin:'auto', fontSize:'20'}}/>
                <span style={{margin:'auto'}}>{f.title}</span>
                
            </ListItemButton>
        </ListItem>
    ));
  
    return (  
        <GenericListDialog open={open} onClose={handleClose} dialogList={dialogList} title={"Available " + projectInfo.projectName + " Filters for " + filterPlatform}/>
    );

  }