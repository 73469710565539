import modelData from "../data/modelData.json"
import projectData from "../data/projectData.json"
import { DbList, ModelDetails, ProjectDetails, ProjectTheme } from "./customTypes";

const models : ()=>ModelDetails[] = () => {return (modelData as DbList).data.concat([])};
const projects : ()=>ProjectDetails[] = () => {return (projectData as DbList).data.concat([])};

const getModelInfo = (modelName:string) => {
    const details: ModelDetails = models().filter((d:ModelDetails)=>d.modelName === modelName)[0];
    return details;
}
const getProjectInfo = (projectId:number) => {
    const details: ProjectDetails = projects().filter((p:ProjectDetails)=>p.projectId === projectId)[0];
    return details;
}

const getLabsInfo = () => {
    const details: ProjectDetails = getProjectInfo(0);
    return details;
}

const getProjectList = () => {
    const projectList : ProjectDetails[] = projects().filter(p=>p.projectId !== 0);
    return projectList;
}
const getModelListByProjectId = (projectId:number) => {
    const projectModelList: ModelDetails[] = models().filter((d:ModelDetails)=>d.projectId === projectId);
    return projectModelList;
}

function componentToHex(c:number) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(r:number, g:number, b:number) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex:string){
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    return {r:r,g:g,b:b};
}

function changeComponentBrightness(c:number, m:number){
    // console.log("component: ",c);
    let c2:number = c*m;
    if(c2>255){
        c2 = 255 * 1/m;
    }
    // console.log("updated component: ",Math.round(c2));
    return Math.round(c2);
}

function changeColorBrightness(hex:string, m:number){
    // console.log("changing color: ",hex, ", with multiplier: ",m);
    const {r,g,b} = hexToRgb(hex);
    const newColor:string = rgbToHex(changeComponentBrightness(r,m),changeComponentBrightness(g,m),changeComponentBrightness(b,m));
    // console.log("new color: ",newColor);
    return newColor;
}

function generateTheme(hex:string) {
    const {r,g,b} = hexToRgb(hex);
    
    const brightnessThreshold = 186;
    const colorBrightness = (r * 0.299 + g * 0.587 + b * 0.114);
    let textColor: string = "#000000";
    let accentColor: string = "#000000";
    if(colorBrightness > brightnessThreshold){
        //light bg
        textColor = "#000000";
        const i:number = 0.75; 
        accentColor = changeColorBrightness(hex,i);
    }else{
        //dark bg
        textColor = "#FFFFFF";
        const i:number = 1.25; 
        accentColor = changeColorBrightness(hex,i);
    }
    const theme: ProjectTheme = {
        bgColor : hex,
        accentColor: accentColor,
        textColor : textColor
    }
    return theme;
}

const getSocialPlatform = (social: string) => {
    switch(social) {
        case 'tt':
            return "TikTok";
        case 'sc':
            return "SnapChat";
        case 'ig':
            return "Instagram";
        case 'yt':
            return "YouTube";
        case 'dc':
            return "Discord";
        case 'tg':
            return "Telegram";
        case 'x':
            return "X";
        default:
            return "Website: "+social;
    }
}

// confirm handling
const handleConfirmExternal = (externalUrl: string, social: string) => {
    const sp = getSocialPlatform(social)
    let usrConfirm = window.confirm("Are you sure you want to leave and visit\n\""+sp+"\"?");
    if (usrConfirm === true) {
        window.open(externalUrl, '_blank');
    }
}

export {getModelInfo,getProjectInfo,getModelListByProjectId,generateTheme,getProjectList,getLabsInfo,handleConfirmExternal,getSocialPlatform};