import { Avatar, Badge } from "@mui/material";
import { ProjectDetails } from "../logic/customTypes";
import { generateTheme, getModelListByProjectId } from "../logic/logic";
import React from "react";
import ModelListDialog from "./Dialogs/ModelListDialog";

interface PreviewTileProps{
    projectDetails : ProjectDetails;
}

function PreviewTile(props : PreviewTileProps) {
    const thisTheme = generateTheme(props.projectDetails.themeColor);
    const models = getModelListByProjectId(props.projectDetails.projectId);
    const modelCount = models.length;
    
    // dialog related
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = (value: string) => {
        setOpen(false);
    };
    return ( 
    <>
        <div style={{
                height:'70px', 
                alignContent:'center', 
                margin:'auto', 
                borderWidth:'2px',
                borderRadius:'5px', 
                borderColor:thisTheme.bgColor, 
                borderStyle:'solid', 
                backgroundColor:thisTheme.accentColor
            }}
            onClick={handleClickOpen} 
        >
            <div style={{
                background: thisTheme.bgColor, 
                color: thisTheme.textColor, 
                width: '60px', 
                height: '48px', 
                display:'flex', 
                flexDirection:'column', 
                borderRadius:'5px', 
                margin: '2px'
            }}>
                <Badge badgeContent={modelCount} color='warning'>
                    <Avatar src={props.projectDetails.logo} style={{
                        margin:'auto',
                        borderWidth: '2px',
                        borderStyle: 'dotted',
                        borderColor: thisTheme.accentColor
                    }}/>
                </Badge>
            </div>
            <div style={{fontSize:'10px', textAlign:'center'}}>
                {props.projectDetails.projectName}
            </div>
        </div>
        <ModelListDialog
            selectedProjectInfo={props.projectDetails}
            open={open}
            onClose={handleClose} 
        />
    </>
    );
}

export {PreviewTile};