import {  Dialog, DialogTitle, List, ListItem, ListItemButton } from "@mui/material";
import { getModelInfo, getModelListByProjectId, getProjectInfo } from "../../logic/logic";
import { useNavigate } from "react-router-dom";
import { ImEye } from "react-icons/im"
import GenericListDialog from "./GenericListDialog";
import { ProjectDetails } from "../../logic/customTypes";

export interface ModelListDialogProps {
    open: boolean;
    onClose: (value: string) => void;
    selectedProjectInfo? : ProjectDetails
    selectedModelName?: string;
  }
  
  export default function ModelListDialog(props: ModelListDialogProps) {
    let { onClose, selectedProjectInfo, selectedModelName, open } = props;
  
    const handleClose = () => {
      onClose("");
    };
  
    const navigate = useNavigate();
    const handleListItemClick = (value: string) => {
        onClose(value);
        return navigate("/"+value);
    };
    let projectId : number = 0;
    if(selectedProjectInfo){
        projectId = selectedProjectInfo.projectId as number;
    }else if(selectedModelName && !selectedProjectInfo){
        const currentModelInfo = getModelInfo(selectedModelName);
        projectId = currentModelInfo.projectId;
    }else{
        projectId = 1;
    }
    const currentProjectInfo = getProjectInfo(projectId);
    const listOfProjectModels = getModelListByProjectId(projectId);
  
    const dialogList = listOfProjectModels.map((arModel) => (
        <ListItem disableGutters key={arModel.modelName}>
            <ListItemButton onClick={() => handleListItemClick(arModel.modelName)} style={{display:'flex', flexDirection:'row', borderTop:'2px solid grey'}}>
              <ImEye style={{margin:'auto', fontSize:'20'}}/>
              <span style={{margin:'auto'}}>{arModel.modelTitle}</span>
            </ListItemButton>
        </ListItem>
    ));

          
    return (
      <GenericListDialog open={open} onClose={onClose} dialogList={dialogList} title={"Available Models"} projectInfo={selectedProjectInfo}/>
    );
  }