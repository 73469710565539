import { Navigate, useParams } from "react-router-dom";
import { generateTheme, getModelInfo, getModelListByProjectId, getProjectInfo, handleConfirmExternal } from "../../logic/logic";
import { ModelDetails, ProjectDetails, SocialFilterDetails } from "../../logic/customTypes";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardHeader, Avatar, IconButton, CardActions, Chip, Badge } from "@mui/material";
import BadgeIcon from '@mui/icons-material/Badge';
import LanguageIcon from '@mui/icons-material/Language';
import ShareIcon from '@mui/icons-material/Share';
import { ReactElement } from "react";
import { FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaArrowsRotate } from "react-icons/fa6";
import {SEO} from "../Seo";
import React from "react";
import ModelListDialog from "../Dialogs/ModelListDialog";
import { BsSnapchat } from "react-icons/bs";
import FiltersListDialog from "../Dialogs/FiltersListDialog";
import { ChipsRow } from "./ChipsRow";

function ModelViewerPro() {
    const {modelName} = useParams();
    const modelDetails : ModelDetails = getModelInfo(modelName as string);
    
    // models dialog related
    const [openModelsDialog, setOpenModelsDialog] = React.useState(false);

    // social filters dialog related
    const [openFiltersDialog, setOpenFiltersDialog] = React.useState(false);
    const [chosenSocialFilters, setChosenSocialFilters] = React.useState([] as SocialFilterDetails[]);

    if(modelDetails){
        const projectDetails : ProjectDetails = getProjectInfo(modelDetails.projectId);
        const modelCount = getModelListByProjectId(projectDetails.projectId).length;
        
        // theme related
        const currentTheme = generateTheme(projectDetails.themeColor);

        // trade chips
        let tokenTradeUrls : string[][] = [];
        projectDetails.tradableAssets.forEach(a => {
            if(a.assetType === 'token'){
                a.chains.forEach(c => {
                    tokenTradeUrls.push([c.name,c.tradingUrl]);
                })
            }
        });
        const tokenTradingChips : ReactElement[] = tokenTradeUrls.map(u => {
                return (
                <Chip key={u[1]} 
                    icon={<FaArrowsRotate style={{background:currentTheme.accentColor, color:currentTheme.textColor}}/>} 
                    label={'Buy on '+u[0]} 
                    style={{background:currentTheme.accentColor, color:currentTheme.textColor}}
                    onClick={()=>{handleConfirmExternal(u[1],u[1])}}
                />)
            }
        );

        // nft trade chips
        let nftTradeUrls : string[][] = [];
        projectDetails.tradableAssets.forEach(a => {
            if(a.assetType === 'nft'){
                a.chains.forEach(c => {
                    nftTradeUrls.push([c.name,c.tradingUrl]);
                })
            }
        });
        const nftTradingChips : ReactElement[] = nftTradeUrls.map(u => {
                return (
                <Chip key={u[1]} 
                    icon={<FaArrowsRotate style={{background:currentTheme.accentColor, color:currentTheme.textColor}}/>} 
                    label={'Buy on '+u[0]} 
                    style={{background:currentTheme.accentColor, color:currentTheme.textColor}}
                    onClick={()=>{handleConfirmExternal(u[1],u[1])}}
                />)
            }
        );

        // social chips
        const socialChips : ReactElement[] = projectDetails.socials.map(s => {
            switch(s.platform) {
                case 'x':
                    return (<Avatar key={s.url} sx={{ width: 24, height: 24, bgcolor: currentTheme.accentColor, color: currentTheme.textColor }} style={{margin:'4px'}}><FaXTwitter size={16} onClick={()=>{handleConfirmExternal(s.url,s.platform)}}/></Avatar>);
                case 'tg':
                    return (<Avatar key={s.url} sx={{ width: 24, height: 24, bgcolor: currentTheme.accentColor, color: currentTheme.textColor }} style={{margin:'4px'}}><FaTelegram size={16} onClick={()=>{handleConfirmExternal(s.url,s.platform)}}/></Avatar>);
                case 'dc':
                    return (<Avatar key={s.url} sx={{ width: 24, height: 24, bgcolor: currentTheme.accentColor, color: currentTheme.textColor }} style={{margin:'4px'}}><FaDiscord size={16} onClick={()=>{handleConfirmExternal(s.url,s.platform)}}/></Avatar>);
                case 'yt':
                    return (<Avatar key={s.url} sx={{ width: 24, height: 24, bgcolor: currentTheme.accentColor, color: currentTheme.textColor }} style={{margin:'4px'}}><FaYoutube size={16} onClick={()=>{handleConfirmExternal(s.url,s.platform)}}/></Avatar>);
                case 'ig':
                    return (<Avatar key={s.url} sx={{ width: 24, height: 24, bgcolor: currentTheme.accentColor, color: currentTheme.textColor }} style={{margin:'4px'}}><FaInstagram size={16} onClick={()=>{handleConfirmExternal(s.url,s.platform)}}/></Avatar>);
                case 'tt':
                    return (<Avatar key={s.url} sx={{ width: 24, height: 24, bgcolor: currentTheme.accentColor, color: currentTheme.textColor }} style={{margin:'4px'}}><FaTiktok size={16} onClick={()=>{handleConfirmExternal(s.url,s.platform)}}/></Avatar>);
                default:
                    return (<span/>);
            }
        });

        
        // social filters dialog related

        const handleClickOpenFiltersDialog = (socialFilters: SocialFilterDetails[], projectDetails: ProjectDetails) => {
            setChosenSocialFilters(socialFilters)
            setOpenFiltersDialog(true);
        };
        
        const handleCloseFiltersDialog = (value: string) => {
            setOpenFiltersDialog(false);
        };

        // social filter chips
        const scFilters = projectDetails.socialFilters.filter(f => f.platform==='sc');
        const ttFilters = projectDetails.socialFilters.filter(f => f.platform==='tt');
        const igFilters = projectDetails.socialFilters.filter(f => f.platform==='ig');
        if(scFilters.length + ttFilters.length + igFilters.length > 0){

        }
        const socialFilterChips : ReactElement[] = [];
        if(scFilters.length>0){
            socialFilterChips.push(
                <Avatar key={scFilters[0].platform} sx={{ width: 24, height: 24, bgcolor: currentTheme.accentColor, color: currentTheme.textColor }} style={{margin:'4px'}}><BsSnapchat size={16} onClick={()=>handleClickOpenFiltersDialog(scFilters, projectDetails)}/></Avatar>
            );
        }
        if(ttFilters.length>0){
            socialFilterChips.push(
                <Avatar key={ttFilters[0].platform} sx={{ width: 24, height: 24, bgcolor: currentTheme.accentColor, color: currentTheme.textColor }} style={{margin:'4px'}}><FaTiktok size={16} onClick={()=>handleClickOpenFiltersDialog(ttFilters, projectDetails)}/></Avatar>
            );

        }
        if(igFilters.length>0){
            socialFilterChips.push(
                <Avatar key={igFilters[0].platform} sx={{ width: 24, height: 24, bgcolor: currentTheme.accentColor, color: currentTheme.textColor }} style={{margin:'4px'}}><FaInstagram size={16} onClick={()=>handleClickOpenFiltersDialog(igFilters, projectDetails)}/></Avatar>
            );

        }

        // models dialog related

        const handleClickOpenModelsDialog = () => {
            setOpenModelsDialog(true);
        };
        
        const handleCloseModelsDialog = (value: string) => {
            setOpenModelsDialog(false);
        };

        return (
            <>
                <SEO
                    title={projectDetails.projectName + ' on Solanacorn AR'}
                    description={modelDetails.description}
                    name='Solanacorn'
                    type='article' 
                />
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <Card style={{ 
                        width: '360px', 
                        height: '640px', 
                        background: currentTheme.bgColor,
                        color: currentTheme.textColor, 
                    }}>
                        <CardHeader
                            avatar={<Avatar src={projectDetails.logo} />}
                            action={
                            <IconButton 
                                style={{background:currentTheme.accentColor, color:currentTheme.textColor}}
                                onClick={()=>{handleConfirmExternal("https://twitter.com/intent/tweet?text=Check out this AR powered by @CornOnSolana: " + window.location.href,"x")}}
                            >
                                <ShareIcon  />
                            </IconButton>}
                            sx={{background:currentTheme.accentColor, color:currentTheme.textColor}}
                            title={modelDetails.modelTitle}
                            subheader={<span style={{background:currentTheme.accentColor, color:currentTheme.textColor}}>{projectDetails.projectName.toUpperCase()}</span>} 
                        />
                        <CardContent>
                            <iframe
                                title={modelDetails.modelName}
                                frameBorder="0"
                                allow="autoplay; fullscreen; xr-spatial-tracking"
                                xr-spatial-tracking="true"
                                execution-while-out-of-viewport="true"
                                execution-while-not-rendered="true"
                                web-share="true"
                                src={"https://sketchfab.com/models/" + modelDetails.modelId + "/embed?ui_infos=0&ui_watermark_link=0&ui_watermark=0"}
                                width="300px"
                                height="300px"
                            > </iframe>
                        </CardContent>


                        {modelDetails.description === "this is disabled for now to make more screen space" && (
                            <CardContent style={{background:currentTheme.accentColor, color:currentTheme.textColor}}>
                                {modelDetails.description}
                            </CardContent>
                        )}
                        <CardActions disableSpacing style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width:'100%', marginTop:'5px' }}>
                                <div style={{ width: '50%', textAlign: 'end', marginRight: '20px' }}><Badge badgeContent={modelCount} color='warning'><Chip icon={<BadgeIcon style={{background:currentTheme.accentColor, color:currentTheme.textColor}} />} label={projectDetails.projectName} onClick={handleClickOpenModelsDialog} style={{background:currentTheme.accentColor, color:currentTheme.textColor}}/></Badge></div>
                                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', textAlign: 'start', marginRight: '20px' }}><Chip icon={<LanguageIcon style={{background:currentTheme.accentColor, color:currentTheme.textColor}}/>} label='Website' component="a" href={projectDetails.website} clickable style={{background:currentTheme.accentColor, color:currentTheme.textColor}} /></div>
                            </div>
                            <ChipsRow title="Socials" chips={socialChips} chipLimit={4} projectInfo={projectDetails}/>
                            {socialFilterChips.length > 0 && (
                                <ChipsRow title="Social AR" chips={socialFilterChips} chipLimit={4} projectInfo={projectDetails}/>
                            )}
                            {tokenTradingChips.length > 0 && (
                                <ChipsRow title="Token Trading" chips={tokenTradingChips} chipLimit={1} projectInfo={projectDetails}/>
                            )}
                            
                            {nftTradingChips.length > 0 && (
                                <ChipsRow title="NFT Trading" chips={nftTradingChips} chipLimit={1} projectInfo={projectDetails}/>
                            )}
                            
                        </CardActions>

                        <ModelListDialog
                            selectedModelName={modelDetails.modelName}
                            open={openModelsDialog}
                            onClose={handleCloseModelsDialog}
                            selectedProjectInfo={projectDetails} />
                        <FiltersListDialog
                            socialFilters={chosenSocialFilters}
                            projectInfo={projectDetails}
                            open={openFiltersDialog}
                            onClose={handleCloseFiltersDialog} />
                    </Card>
                </div>
            </>
        );
    }
    else
    {
        return (<Navigate to="/" />)
    }
  }

export {ModelViewerPro};