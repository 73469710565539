import { Chip, ListItem, ListItemButton, Typography } from "@mui/material";
import { ReactElement } from "react";
import GenericListDialog from "../Dialogs/GenericListDialog";
import { ImEye } from "react-icons/im";
import { FaArrowsRotate } from "react-icons/fa6";
import { ProjectDetails, ProjectTheme } from "../../logic/customTypes";
import React from "react";
import { generateTheme } from "../../logic/logic";

interface ChipsRowProps{
    title : string;
    chips : ReactElement[];
    chipLimit: number;
    projectInfo: ProjectDetails;
}

const ChipsRow = (props: ChipsRowProps)=>{
    const [openChipDialog, setOpenChipDialog] = React.useState(false);
    const onClose = () => {setOpenChipDialog(false)};
    
    const launchDialog = () => {setOpenChipDialog(true)};
    
    if(props.chips.length > props.chipLimit){
        const currentTheme = generateTheme(props.projectInfo.themeColor);
        const dialogList = props.chips.map((chip,i) => (
            <ListItem disableGutters key={i+'_chip'}>
                <ListItemButton onClick={() => onClose()} style={{display:'flex', flexDirection:'row', justifyContent:'center', borderTop:'2px solid grey'}}>
                  {chip}
                </ListItemButton>
            </ListItem>
        ));

        return(
            <div style={{ display: 'flex', flexDirection: 'row', width:'100%', marginTop:'5px' }}>
                <div style={{ width: '50%', textAlign: 'end', marginRight: '20px' }}><Typography>{props.title}: </Typography></div>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', textAlign: 'start', marginRight: '20px' }}>
                    <Chip
                        icon={<ImEye style={{background:currentTheme.accentColor, color:currentTheme.textColor}}/>} 
                        label={'See ' + props.chips.length + ' Options'} 
                        style={{background:currentTheme.accentColor, color:currentTheme.textColor}}
                        onClick={()=>{launchDialog()}}
                    />
                </div>
            <GenericListDialog open={openChipDialog} onClose={onClose} dialogList={dialogList} title={props.projectInfo.projectName + ' ' + props.title} projectInfo={props.projectInfo}/>
            </div>
        );
    }else{
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width:'100%', marginTop:'5px' }}>
                <div style={{ width: '50%', textAlign: 'end', marginRight: '20px' }}><Typography>{props.title}: </Typography></div>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', textAlign: 'start', marginRight: '20px' }}>{props.chips}</div>
            </div>
        );
    }
}

export {ChipsRow}